import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { arrayOf, bool, number, shape, string } from 'prop-types'
import { useEffect, useMemo } from 'react'
import FormControlButtons from '../../Form/components/FormControlButtons/FormControlButtons'
import ListTable from '../../ListTable/components/ListTable'
import TenantTagsCell from '../../TableCells/TenantTagsCell/TenantTagsCell'
import useTenantTags from '../../TenantTags/api/useTenantTags'
import Alert from '../../Alert/Alert'
import useCreateOrUpdateBaselineGroup from '../api/useCreateOrUpdateBaselineGroup'
import FormTextInput from '../../Form/components/FormTextInput/FormTextInput'
import tenantTypeFilter from '../../TableCells/TenantTypeCell/filters'
import TenantTypeCell from '../../TableCells/TenantTypeCell/TenantTypeCell'
import uniqueTenantTags from '../../../utils/uniqueTenantTags'
import { tenantShape } from '../../../utils/propTypes/tenantAlignmentProps'

const BaselineGroupForm = ({ baselineToEdit, isEditMode, tenants }) => {
  const {
    name: initialName,
    alignedThreshold: initialAlignedThreshold,
    semiAlignedThreshold: initialSemiAlignedThreshold,
  } = baselineToEdit || {}
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: initialName || '',
      alignedThreshold: initialAlignedThreshold || 100,
      semiAlignedThreshold: initialSemiAlignedThreshold || 80,
      baselineTenant: isEditMode
        ? tenants.filter(
            tenant =>
              baselineToEdit.baselineClientTenantId === tenant.clientTenantId
          )
        : [],
      alignedTenants: isEditMode
        ? tenants.filter(tenant =>
            baselineToEdit.clientTenantIds.includes(tenant.clientTenantId)
          )
        : [],
    },
  })

  const selectedBaselineTenant = watch('baselineTenant')

  useEffect(() => {
    const selectedAlignedTenants = getValues('alignedTenants')
    const validSelectableTenants = selectedAlignedTenants.filter(
      selectedAlignedTenant =>
        selectedBaselineTenant[0]?.isSharedBaseline ||
        (selectedBaselineTenant[0]?.isBaseline &&
          !selectedAlignedTenant.isBaseline)
    )
    setValue('alignedTenants', validSelectableTenants)
  }, [getValues, selectedBaselineTenant, setValue])

  const {
    data: tenantTags,
    isPending: isTenantTagsLoading,
    isError: isTenantTagsError,
    error: tenantTagsError,
  } = useTenantTags()

  const createOrUpdateBaselineGroup = useCreateOrUpdateBaselineGroup({
    onSuccess: () => {
      navigate('/tenant-management?tab=baselines')
    },
    isUpdate: isEditMode,
  })

  const onSubmit = ({
    name,
    alignedThreshold,
    semiAlignedThreshold,
    alignedTenants,
    baselineTenant,
  }) => {
    createOrUpdateBaselineGroup.mutate({
      name,
      alignedThreshold,
      semiAlignedThreshold,
      baselineClientTenantId: baselineTenant[0].clientTenantId,
      clientTenantIds: alignedTenants.map(tenant => tenant.clientTenantId),
      id: isEditMode ? baselineToEdit.clientTenantGroupId : undefined,
    })
  }

  const baselineTenants = useMemo(
    () =>
      tenants.filter(tenant => tenant.isBaseline || tenant.isSharedBaseline),
    [tenants]
  )

  const alignedTenants = useMemo(
    () => tenants.filter(tenant => !tenant.isSharedBaseline),
    [tenants]
  )

  const baselineTenantsUniqueTags = uniqueTenantTags({
    tenants: baselineTenants,
    tenantTags,
  })

  const baselineColumns = useMemo(
    () => [
      {
        header: 'Tenant name',
        accessor: 'tenantFriendlyName',
        initialSort: 'asc',
      },
      {
        header: 'Type',
        accessor: 'type',
        filterVariant: 'multi-select',
        filterSelectOptions: [
          'Inforcer Managed',
          'Shared Baseline',
          'Baseline',
          'Customer',
        ],
        filterFn: tenantTypeFilter,
        cell: ({ row }) => TenantTypeCell({ row }),
        grow: true,
        size: 150,
      },
      {
        header: 'Tags',
        accessor: 'tags',
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        enableColumnFilter: true,
        filterVariant: 'multi-select',
        filterSelectOptions: baselineTenantsUniqueTags.map(tag => ({
          value: tag.id,
          label: tag.tag,
        })),
        size: 500,
        minSize: 100,
        maxSize: 800,
        grow: true,
        sx: { overflow: 'hidden' },
      },
    ],
    [baselineTenantsUniqueTags, tenantTags]
  )

  const alignedTenantsUniqueTags = uniqueTenantTags({
    tenants: alignedTenants,
    tenantTags,
  })

  const alignedTenantsColumns = useMemo(
    () => [
      {
        header: 'Tenant name',
        accessor: 'tenantFriendlyName',
      },
      {
        header: 'Type',
        accessor: 'type',
        filterVariant: 'multi-select',
        filterSelectOptions: ['Baseline', 'Customer'],
        filterFn: tenantTypeFilter,
        cell: ({ row }) => TenantTypeCell({ row }),
        grow: true,
        size: 150,
      },
      {
        header: 'Tags',
        accessor: 'tags',
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        enableColumnFilter: true,
        filterVariant: 'multi-select',
        filterSelectOptions: alignedTenantsUniqueTags.map(tag => ({
          value: tag.id,
          label: tag.tag,
        })),
        size: 500,
        minSize: 100,
        maxSize: 800,
        grow: true,
        sx: { overflow: 'hidden' },
      },
    ],
    [alignedTenantsUniqueTags, tenantTags]
  )

  if (isTenantTagsError) {
    return (
      <div className='shared-baselines-page md:container mx-auto'>
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <Alert
            type='error'
            title={tenantTagsError.response.data.message}
            margin='mb-4'
          >
            {tenantTagsError.response.data.errors?.map(error => (
              <ul key={error}>
                <li>{error}</li>
              </ul>
            ))}
          </Alert>
        </div>
      </div>
    )
  }

  // disables the row only if the selected baseline tenant is not a shared baseline and
  // the row is a baseline tenant
  const isAlignedTenantRowEnabled = row =>
    selectedBaselineTenant[0]?.isSharedBaseline ||
    (selectedBaselineTenant[0]?.isBaseline && !row.original.isBaseline)

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      aria-label='baseline-group-form'
      className='flex flex-col gap-6'
    >
      <div className='w-fit flex flex-col gap-4'>
        <FormTextInput
          label='Name'
          register={() =>
            register('name', {
              required: 'Name is required',
              maxLength: {
                value: 60,
                message: 'Name must be less than 60 characters',
              },
            })
          }
          errors={errors.name}
        />
        <div className='flex flex-row gap-6'>
          <FormTextInput
            label='Alignment Threshold'
            type='number'
            register={() =>
              register('alignedThreshold', {
                valueAsNumber: true,
                required: 'Alignment threshold is required',
                min: {
                  value: 0,
                  message: 'Alignment threshold must be greater than 0',
                },
                max: {
                  value: 100,
                  message: 'Alignment threshold must be less than 100',
                },
              })
            }
            errors={errors.alignedThreshold}
          />
          <FormTextInput
            label='Semi-aligned Threshold'
            type='number'
            register={() =>
              register('semiAlignedThreshold', {
                valueAsDate: true,
                required: 'Semi-aligned threshold is required',
                min: {
                  value: 0,
                  message: 'Semi-aligned threshold must be greater than 0',
                },
                max: {
                  value: 100,
                  message: 'Semi-aligned threshold must be less than 100',
                },
              })
            }
            errors={errors.semiAlignedThreshold}
            placeholder='Semi-aligned threshold'
            className={`flex rounded border p-2 bg-white text-gray-700 focus:outline-blue-400 ${errors.semiAlignedThreshold ? 'border-red-500 focus:outline-red-500' : 'border-gray-400'}`}
          />
        </div>
      </div>
      <div className='pt-2'>
        <h3 className='text-xl'>Baseline Tenants</h3>
        <Controller
          control={control}
          name='baselineTenant'
          render={({ field: { onChange, value } }) => (
            <ListTable
              enableMultiRowSelection={false}
              enableRowSelection
              uniqueKey='clientTenantId'
              columns={baselineColumns}
              data={baselineTenants}
              loading={isTenantTagsLoading}
              setSelectedListItems={onChange}
              selectedListItems={value}
            />
          )}
        />
      </div>

      <div>
        <h3 className='text-xl'>Aligned Tenants</h3>
        <Controller
          control={control}
          name='alignedTenants'
          render={({ field: { onChange, value } }) => (
            <ListTable
              enableMultiRowSelection
              enableRowSelection={isAlignedTenantRowEnabled}
              uniqueKey='clientTenantId'
              columns={alignedTenantsColumns}
              data={alignedTenants}
              loading={isTenantTagsLoading}
              setSelectedListItems={onChange}
              selectedListItems={value}
            />
          )}
        />
      </div>
      <FormControlButtons
        onCancel={() => navigate('/tenant-management?tab=baselines')}
        onReset={() => reset()}
        cancelButtonText='Back'
        confirmButtonText={isEditMode ? 'Update' : 'Create'}
        resetDisabled={!isDirty || createOrUpdateBaselineGroup.isPending}
        confirmDisabled={
          !isDirty ||
          Object.values(errors).length !== 0 ||
          createOrUpdateBaselineGroup.isPending
        }
      />
    </form>
  )
}

BaselineGroupForm.defaultProps = {
  isEditMode: false,
  baselineToEdit: {},
}

BaselineGroupForm.propTypes = {
  baselineToEdit: shape({
    name: string,
    alignedThreshold: number,
    semiAlignedThreshold: number,
    clientTenantGroupId: string,
  }),
  tenants: arrayOf(tenantShape).isRequired,
  isEditMode: bool,
}

export default BaselineGroupForm
