import { useState } from 'react'
import { bool, func, shape, string } from 'prop-types'
import Modal from '../Modal/Modal'
import useClientVariables from './api/useClientVariables'
import useClientTenantVariables from './api/useClientTenantVariables'
import EditClientTenantVariablesForm from './EditClientTenantVariablesForm'
import ClientTenantVariablesSearchBar from './ClientTenantVariablesSearchBar'

const EditClientTenantVariablesModal = ({ tenant, isOpen, setModalOpen }) => {
  const clientVariables = useClientVariables()
  const clientTenantVariables = useClientTenantVariables(
    tenant.clientTenantId,
    isOpen
  )

  const [searchTerm, setSearchTerm] = useState(undefined)

  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchTerm('')
  }

  const filteredVariables = clientVariables?.data?.filter(cv =>
    searchTerm
      ? cv.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cv.description?.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  )

  return (
    <Modal
      isOpen={isOpen}
      setModalOpen={setModalOpen}
      closeOnBlur={false}
      fullHeight={false}
    >
      <h2 className='text-xl font-bold mb-4'>
        Update tenant variables - <strong>{tenant.tenantFriendlyName}</strong>
      </h2>
      <div className='searchbar w-full rounded-xl overflow-hidden p-2'>
        <ClientTenantVariablesSearchBar
          searchQuery={searchTerm}
          handleSearchChange={handleSearchChange}
          handleClearSearch={handleClearSearch}
        />
      </div>

      <EditClientTenantVariablesForm
        clientVariables={filteredVariables}
        clientTenantVariables={clientTenantVariables.data}
        tenant={tenant}
        closePanel={() => setModalOpen(false)}
        onSuccessfulAction={() => setModalOpen(false)}
        isLoading={clientTenantVariables.isPending}
      />
    </Modal>
  )
}

EditClientTenantVariablesModal.defaultProps = {
  isOpen: false,
}

EditClientTenantVariablesModal.propTypes = {
  tenant: shape({
    tenantFriendlyName: string.isRequired,
  }).isRequired,
  isOpen: bool,
  setModalOpen: func.isRequired,
}

export default EditClientTenantVariablesModal
