// It's in both -> delete from selected
// It's in only target -> delete from selected
// It's in only baseline -> delete from baseline
export const shouldDeleteFromBaseline = (
  isMissingFromSubject,
  isMissingFromBaseline
) => isMissingFromSubject && !isMissingFromBaseline

export const driftTypes = {
  driftDetected: 'Drift Detected',
  newDeviationDetected: 'New Deviation Detected',
  noDriftDetected: 'No Drift Detected',
  deviationNoLongerDetected: 'Deviation No Longer Detected',
}

export const isDriftPolicy = policyDataDiff =>
  policyDataDiff[0]?.driftedDiff?.some(
    ({ driftType }) =>
      driftType === driftTypes.driftDetected ||
      driftType === driftTypes.newDeviationDetected ||
      driftType === driftTypes.noDriftDetected ||
      driftType === driftTypes.deviationNoLongerDetected
  )

// there are 3 cases we need to account for when deleting a policy
// 1 - The policy exists in both the baseline and the selected tenant where we need to use the comparatorPolicyGuid
// 2 - The policy exists only in the customer tenant where we need to use the policyGuid and the value is
//     set to the GUID in the selected tenant
// 3 - The policy exists only in the baseline tenant where we need to use the policyGuid and the value is
//     set to the GUID in the baseline tenant
export const getPolicyGuid = policy =>
  !policy.isMissingFromSubject && !policy.isMissingFromBaseline
    ? policy.comparatorPolicyGuids[0]
    : policy.policyGuid

export const steps = {
  configuration: 'configuration',
  review: 'review',
  final: 'final',
}

export default {
  shouldDeleteFromBaseline,
  steps,
  getPolicyGuid,
  isDriftPolicy,
  driftTypes,
}
