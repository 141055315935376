import { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Axios from 'axios'

import { Link, useNavigate } from 'react-router-dom'
import PolicyPickerV2 from '../../components/Policies/PolicyPicker/PolicyPicker'
import TenantSkeleton from '../../components/LoadingSkeletons/TenantSkeleton'
import Alert from '../../components/Alert/Alert'

import { isExchangePolicy } from '../../utils/Policy'
import {
  additionalSettingsConfig,
  getFlagsForPolicy,
  updateAdditionalSettingValue,
  updateNestedAdditionalSetting,
} from '../../components/AdditionalOptions/AdditionalOptionsConfig'
import AdditionalDeploymentOptions from '../../components/AdditionalOptions/AdditionalDeploymentOptions'
import FormStepper from '../../components/FormStepper/FormStepper'

import DeployPolicyReviewSummary from '../../components/TenantAlignment/DeployPolicyReviewSummary'
import DeployPolicyFinalSummary from '../../components/PolicyDeployment/DeployPolicyFinalSummary'
import Modal from '../../components/Modal/Modal'
import ConfirmationPopupSkeleton from '../../components/LoadingSkeletons/ConfirmationPopupSkeleton'
import { useNotificationsContext } from '../../contexts/NotificationsContext'

import ListTable from '../../components/ListTable/components/ListTable'
import SelectedItems from '../../components/ListTable/components/SelectedItems'

import useGetTenants from '../../components/SharedBaselines/api/useGetTenants'
import useGetListTenants from '../../hooks/useGetListTenants'

import TenantTypeCell from '../../components/TableCells/TenantTypeCell/TenantTypeCell'

import tenantTypeFilter from '../../components/TableCells/TenantTypeCell/filters'
import TenantTagsCell from '../../components/TableCells/TenantTagsCell/TenantTagsCell'
import useTenantTags from '../../components/TenantTags/api/useTenantTags'
import uniqueTenantTags from '../../utils/uniqueTenantTags'

import { useSelection } from '../../components/Policies/PolicyPicker/context/SelectionContext'

import { TenantProvider } from '../../contexts/TenantContext'
import { useExpansion } from '../../components/Policies/PolicyPicker/context/ExpansionContext'

const deployFormConfig = [
  {
    stepHeading: 'Select Source',
    stepBody: 'Pick the tenant to deploy from',
    stepKey: 'stepKey1',
  },
  {
    stepHeading: 'Select Policies',
    stepBody: 'Select the policies you would like to deploy from Source',
    stepKey: 'stepKey2',
  },
  {
    stepHeading: 'Select Destination',
    stepBody: 'Select destination tenant(s) to deploy your policies to',
    stepKey: 'stepKey3',
  },
  {
    stepHeading: 'Additional settings',
    stepBody: 'Select how your policies should be deployed',
    stepKey: 'stepKey4',
  },
  {
    stepHeading: 'Confirm Changes',
    stepBody: 'Confirm you are happy to proceed with deployment',
    stepKey: 'stepKey5',
  },
]

const Deploy = () => {
  const queryClient = useQueryClient()

  const {
    state: { selectedPolicies },
    resetSelectionState,
  } = useSelection()

  const { resetExpansionState } = useExpansion()

  // Form Logic
  const [formNo, setFormNo] = useState(1)

  const { refetchNotifications } = useNotificationsContext()

  // Loading
  const [loadingState, setLoadingState] = useState(true)

  // tells us whether a tenant is a shared baseline tenant
  const {
    data: tenants,
    isLoading: isTenantsLoading,
    isError: isTenantsError,
  } = useGetTenants()

  // used for destination tenants
  const {
    data: listDestinationTenants = [],
    isLoading: isListDestinationTenantsLoading,
    isError: isListDestinationError,
    error: listDestinationTenantsError,
  } = useGetListTenants()

  const { data: tenantTags, isLoading: isTenantTagsLoading } = useTenantTags()

  const [selectedDestinationTenants, setSelectedDestinationTenants] = useState(
    []
  )
  const [selectedSourceTenant, setSelectedSourceTenant] = useState([])

  // Additional options
  // - creates data structure to manage boolean choices for ptids
  const [additionalSettings, setAdditionalSettings] = useState(
    JSON.parse(JSON.stringify(additionalSettingsConfig))
  )

  // Parse data for posting
  const [formResponses, setFormResponses] = useState([])

  // Final response
  const [finalResponse, setFinalResponse] = useState(null)
  const [backEndErrors, setBackEndErrors] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)

  const navigate = useNavigate()

  // Timeline navigation
  const next = () => {
    if (formNo === 1) {
      if (!selectedSourceTenant) {
        return
      }

      const stepOneRes = { step: formNo, response: selectedSourceTenant }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If selected a different tenant then reset the formResponses
        if (
          selectedSourceTenant[0].clientTenantId !==
          formResponses[existingResponseIndex].response[0].clientTenantId
        ) {
          setFormResponses([stepOneRes])
          resetSelectionState()
        }
      } else {
        // Clicking next will create a response in formResponses for step one [0]
        setFormResponses([stepOneRes])
      }

      setFormNo(formNo + 1)
    } else if (formNo === 2) {
      if (selectedPolicies.length === 0) {
        return
      }

      const stepTwoRes = { step: formNo, response: selectedPolicies }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepTwoRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepTwoRes,
        ])
      }

      resetExpansionState()

      setFormNo(formNo + 1)
    } else if (formNo === 3) {
      if (selectedDestinationTenants.length === 0) {
        return
      }

      const stepThreeRes = {
        step: formNo,
        response: selectedDestinationTenants,
      }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepThreeRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepThreeRes,
        ])
      }

      setFormNo(formNo + 1)
    } else if (formNo === 4) {
      const stepFourRes = { step: formNo, response: additionalSettings }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepFourRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepFourRes,
        ])
      }

      setFormNo(formNo + 1)
    } else if (formNo === 5) {
      setFormNo(formNo + 1)
    } else {
      toast.error('Please complete all input fields')
    }
  }

  const pre = () => {
    if (formNo === 2) {
      // Reset destination tenant selection
      setSelectedDestinationTenants([])
      resetExpansionState()
    }
    setFormNo(formNo - 1)
  }

  const finalSubmit = () => {
    setOpenPopup(true)

    const clientTenantIdDeployFrom =
      +formResponses[0].response[0].clientTenantId

    const dTenants = []
    formResponses[2].response.forEach(r => {
      const eachClient = r.clientTenantId
      dTenants.push(eachClient)
    })

    const deploymentBody = []

    selectedPolicies.forEach(policy => {
      const { policyTypeId, displayName, id, priority } = policy

      const additionalSettingsForPolicy = getFlagsForPolicy(
        additionalSettings,
        policyTypeId
      )

      dTenants.forEach(clientTenantIdDeployTo => {
        const destinationTenant = selectedDestinationTenants.find(
          dTenant =>
            dTenant.clientTenantId.toString() ===
            clientTenantIdDeployTo.toString()
        )

        if (
          destinationTenant &&
          !destinationTenant.exchange &&
          isExchangePolicy(policyTypeId)
        ) {
          return
        }

        const currentPolicy = {
          clientTenantIdDeployFrom,
          clientTenantIdDeployTo: +clientTenantIdDeployTo,
          policyGuid: id,
          policyTypeId,
          displayName,
          priority,
          ...additionalSettingsForPolicy,
        }

        if (
          currentPolicy.deployAllUsers === true ||
          currentPolicy.deployAllDevices === true ||
          currentPolicy.deployAllUsersAndDevices === true
        ) {
          currentPolicy.deployAssignments = false
        }

        deploymentBody.push(currentPolicy)
      })
    })

    const deploymentRequest = async () => {
      setLoadingState(true)
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/send-deployment`,
          deploymentBody
        )

        const { confirmationData } = response.data
        const { errorMessages } = response.data

        setBackEndErrors(errorMessages)
        setFinalResponse(confirmationData)

        const uniqueDestinationTenantIds = new Set()

        deploymentBody.forEach(policy => {
          uniqueDestinationTenantIds.add(policy.clientTenantIdDeployTo)
        })

        uniqueDestinationTenantIds.forEach(destinationTenantId =>
          queryClient.resetQueries({
            queryKey: ['policyData', destinationTenantId],
            exact: true,
          })
        )

        refetchNotifications()
      } catch (deploymentError) {
        setBackEndErrors(deploymentError)
      } finally {
        setLoadingState(false)
      }
    }

    deploymentRequest()
  }

  const uniqueTags = uniqueTenantTags({
    tenants: tenants?.data,
    tenantTags,
  })

  const tenantsColumns = useMemo(
    () => [
      {
        header: 'Tenant Name',
        accessor: 'tenantFriendlyName',
      },
      {
        header: 'Type',
        accessor: 'type',
        size: 200,
        filterVariant: 'multi-select',
        filterSelectOptions: [
          'Baseline',
          'Shared Baseline',
          'Inforcer Managed',
          'Customer',
        ],
        filterFn: tenantTypeFilter,
        cell: ({ row }) => TenantTypeCell({ row }),
      },

      {
        header: 'Tags',
        accessor: 'tags',
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        size: 700,
        minSize: 100,
        maxSize: 800,
        grow: true,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueTags.map(tag => ({
          value: tag.id,
          label: tag.tag,
        })),
      },
    ],
    [tenantTags, uniqueTags]
  )

  const filteredDestinationTenants = useMemo(
    () =>
      listDestinationTenants.filter(
        tenant =>
          tenant.clientTenantId !==
          formResponses[0]?.response[0]?.clientTenantId
      ) ?? [],
    [formResponses, listDestinationTenants]
  )

  return (
    <div className='ui-panel deploy-journey '>
      <div className='action-body '>
        <div className='progress-bar'>
          <div className='heading'>
            <h2>Deploy</h2>
            <p>Deploy Policies to your chosen Tenant(s)</p>
          </div>

          <FormStepper
            currentStepNumber={formNo}
            formConfig={deployFormConfig}
          />
        </div>
        <div className='mt-[50px] action-body-form px-1.5'>
          {isTenantsLoading && (
            <TenantSkeleton loadingTitle='Loading tenants...' />
          )}
          {formNo === 1
            ? !isTenantsLoading &&
              !isTenantsError && (
                <>
                  <ListTable
                    selectedListItems={selectedSourceTenant}
                    setSelectedListItems={setSelectedSourceTenant}
                    uniqueKey='clientTenantId'
                    columns={tenantsColumns}
                    data={tenants?.data ?? []}
                    enableMultiRowSelection={false}
                    loading={isTenantTagsLoading}
                    enableRowSelection
                  />

                  <div className='mt-6 gap-3 flex'>
                    <button
                      type='button'
                      onClick={next}
                      disabled={selectedSourceTenant.length === 0}
                      className='btn cyan-btn'
                    >
                      Next
                    </button>
                  </div>
                </>
              )
            : isTenantsError && (
                <Alert
                  type='error'
                  title={isTenantsError.response.data.message}
                  margin='mb-4'
                >
                  {isTenantsError.response.data.errors?.map(error => (
                    <ul>
                      <li>{error}</li>
                    </ul>
                  ))}
                </Alert>
              )}
          {formNo === 2 && (
            <>
              <TenantProvider tenant={selectedSourceTenant}>
                <PolicyPickerV2 />
              </TenantProvider>

              <div className='mt-6 ml-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
                <button
                  type='button'
                  onClick={next}
                  disabled={selectedPolicies.length === 0}
                  className='btn cyan-btn'
                >
                  Next
                </button>
              </div>
            </>
          )}
          {formNo === 3
            ? !isListDestinationTenantsLoading && (
                <>
                  <SelectedItems
                    title='Source tenant:'
                    selectedListItems={[
                      formResponses[0].response[0].tenantFriendlyName,
                    ]}
                  />

                  <SelectedItems
                    title={
                      selectedDestinationTenants.length > 0
                        ? 'Selected tenants:'
                        : 'Destination tenants: '
                    }
                    selectedListItems={selectedDestinationTenants.map(
                      tenant => tenant.tenantFriendlyName
                    )}
                  />

                  {listDestinationTenants?.filter(
                    tenant =>
                      tenant.exchange === false &&
                      selectedPolicies.some(policy =>
                        isExchangePolicy(policy.policyTypeId)
                      )
                  ).length > 0 && (
                    <div className='py-2'>
                      <Alert
                        type='warning'
                        title='Exchange is disabled for some tenant(s)'
                      >
                        The following tenants do not have exchange enabled and
                        must be
                        <Link
                          className='underline px-1 text-blue-500'
                          to='/tenant-management'
                        >
                          refreshed
                        </Link>
                        before Exchange policies can be deployed to them.
                        {listDestinationTenants
                          ?.filter(
                            tenant =>
                              tenant.exchange === false &&
                              selectedPolicies.some(policy =>
                                isExchangePolicy(policy.policyTypeId)
                              )
                          )
                          .map(tenant => (
                            <li className='pt-1' key={tenant.id}>
                              {tenant.tenantFriendlyName}
                            </li>
                          ))}
                      </Alert>
                    </div>
                  )}
                  <ListTable
                    selectedListItems={selectedDestinationTenants}
                    setSelectedListItems={setSelectedDestinationTenants}
                    uniqueKey='clientTenantId'
                    columns={tenantsColumns}
                    data={filteredDestinationTenants}
                    enableMultiRowSelection
                    enableRowSelection={row =>
                      !(
                        !row.original.exchange &&
                        selectedPolicies.some(policy =>
                          isExchangePolicy(policy.policyTypeId)
                        )
                      )
                    }
                  />
                  <div className='mt-6 gap-3 flex'>
                    <button
                      type='button'
                      onClick={pre}
                      className='btn navy-btn'
                    >
                      Previous
                    </button>
                    <button
                      type='button'
                      onClick={next}
                      disabled={selectedDestinationTenants.length === 0}
                      className='btn cyan-btn'
                    >
                      Next
                    </button>
                  </div>
                </>
              )
            : isListDestinationError && (
                <Alert
                  type='error'
                  title={listDestinationTenantsError.response.data.message}
                  margin='mb-4'
                >
                  {listDestinationTenantsError.response.data.errors?.map(
                    error => (
                      <ul>
                        <li>{error}</li>
                      </ul>
                    )
                  )}
                </Alert>
              )}
          {formNo === 4 && (
            <>
              <h3 className='mb-4'>Additional Settings</h3>

              <AdditionalDeploymentOptions
                additionalSettingsConfig={additionalSettings.filter(setting =>
                  selectedPolicies.some(
                    item => item.policyTypeId === setting.policyId
                  )
                )}
                onChange={(policyId, flagName, newValue) => {
                  setAdditionalSettings(prevState =>
                    updateAdditionalSettingValue(
                      prevState,
                      policyId,
                      flagName,
                      newValue
                    )
                  )
                }}
                onNestedFlagChange={(
                  policyId,
                  parentFlagName,
                  nestedFlagName,
                  newValue
                ) => {
                  setAdditionalSettings(prevState =>
                    updateNestedAdditionalSetting(
                      prevState,
                      policyId,
                      parentFlagName,
                      nestedFlagName,
                      newValue
                    )
                  )
                }}
              />
              <div className='mt-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
                <button type='button' onClick={next} className='btn cyan-btn'>
                  Next
                </button>
              </div>
            </>
          )}
          {formNo === 5 && (
            <div>
              <div className='final-confirmation'>
                <DeployPolicyReviewSummary
                  sourceTenantName={selectedSourceTenant[0].tenantFriendlyName}
                  onSubmit={finalSubmit}
                  onPrevious={pre}
                  selectedPolicies={selectedPolicies.map(
                    ({ policyTypeId, id, displayName, name, policyName }) => ({
                      policyTypeId,
                      policyGuid: id,
                      displayName: displayName || name || policyName,
                    })
                  )}
                  destinationTenants={formResponses[2].response}
                  additionalSettings={additionalSettings}
                  contentAlignment='left'
                />
                <Modal
                  isOpen={openPopup}
                  setModalOpen={setOpenPopup}
                  closeOnBlur={false}
                >
                  {loadingState ? (
                    <ConfirmationPopupSkeleton loadingTitle='Deployment in progress...' />
                  ) : (
                    <DeployPolicyFinalSummary
                      successfulPolicies={finalResponse}
                      erroredPolicies={backEndErrors}
                      additionalPolicySettings={additionalSettings.filter(
                        setting =>
                          selectedPolicies.some(
                            item => item.policyTypeId === setting.policyId
                          )
                      )}
                      sourceTenantName={
                        selectedSourceTenant[0].tenantFriendlyName
                      }
                      destinationTenants={formResponses[2].response}
                      onClose={() => {
                        setOpenPopup(false)
                        navigate('/')
                      }}
                    />
                  )}
                </Modal>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Deploy
