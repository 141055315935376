import { arrayOf, number, oneOfType, shape, string } from 'prop-types'

const RestoreSummary = ({ formResponses, selectedBackup }) => (
  <ul>
    {formResponses.map(response => (
      <li key={`form-step-${response.step}`}>
        {response.step === 1 && (
          <div>
            <p>
              <strong>Tenant to restore </strong>
            </p>
            <ul>
              {response.response.map(tenant => (
                <li key={tenant.tenantFriendlyName}>
                  {tenant.tenantFriendlyName}
                </li>
              ))}
            </ul>
          </div>
        )}
        {response.step === 2 && (
          <div>
            <p>
              <strong>Selected Backup </strong>
            </p>
            <p>{selectedBackup.backupRunTime || selectedBackup.comment}</p>
          </div>
        )}
        {response.step === 3 && (
          <div>
            <p>
              <strong>Policies to restore - {response.response.length} </strong>
            </p>
            <ul className='confirm-policies'>
              {response.response.map(policy => (
                <li key={policy.id} className='sm-text'>
                  {policy.displayName || policy.name || policy.PolicyName}
                </li>
              ))}
            </ul>
          </div>
        )}
      </li>
    ))}
  </ul>
)

export default RestoreSummary

RestoreSummary.propTypes = {
  selectedBackup: shape({
    backupRunTime: string,
    comment: string,
  }).isRequired,
  formResponses: arrayOf(
    shape({
      step: number.isRequired,
      response: oneOfType([
        arrayOf(
          shape({
            tenantFriendlyName: string,
          })
        ),
        arrayOf(
          shape({
            value: string,
          })
        ),
        arrayOf(
          shape({
            displayName: string,
            name: string,
            PolicyName: string,
          })
        ),
        arrayOf(string),
        shape({}),
        string,
      ]),
    })
  ).isRequired,
}
