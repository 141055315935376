import { useMemo } from 'react'
import { arrayOf, bool, func, number } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer } from '@fortawesome/free-solid-svg-icons'

import { useTenantRefreshStateContext } from '../../contexts/TenantRefreshStateContext'
import AlignmentLight from './AlignmentLight'
import PolicyLibraryTag from '../SharedBaselines/components/PolicyLibraryTag'
import { formatToLocalTimeNoSeconds } from '../HomeDashboard/utils'
import { hexToRgb } from '../../utils/colours'
import Tag from '../Tag/Tag'
import TagsPopover from '../TenantTags/components/TagsPopover'
import uniqueTenantTags from '../../utils/uniqueTenantTags'
import {
  summaryTenantShape,
  tenantTagsShape,
} from '../../utils/propTypes/tenantAlignmentProps'

/**
 *
 * @param tenantSummary the tenant summary object
 * @param isSelected whether the tenant is selected
 * @param onClick the function to call when the tenant is clicked
 * @param onBackupClick the function to call when the backup button is clicked
 * @param tenantBeingBackedUp the client tenant id being backed up
 * @param isBaselineTenant whether the tenant is the baseline tenant
 * @param alignedThreshold the aligned threshold
 * @param semiAlignedThreshold the semi-aligned threshold
 * @returns {JSX.Element}
 */
const AlignmentTenant = ({
  tenantSummary,
  isSelected,
  onClick,
  onBackupClick,
  isBaselineTenant,
  alignedThreshold,
  semiAlignedThreshold,
  tenantTags,
}) => {
  const { isTenantBeingRefreshed } = useTenantRefreshStateContext()

  const {
    refreshProgress: { isRefreshing, inProgress, failed, succeeded },
    clientTenantId,
    alignmentScore,
    lastBackupTimestamp,
    tenantFriendlyName,
    isSharedBaseline,
  } = tenantSummary

  const tenantBeingBackedUp = isTenantBeingRefreshed(clientTenantId)

  const total = failed + succeeded + inProgress

  const uniqueTags = uniqueTenantTags({ tenants: [tenantSummary], tenantTags })
  const visibleTags = useMemo(() => uniqueTags.slice(0, 2), [uniqueTags])
  const hiddenTags = useMemo(() => uniqueTags.slice(2), [uniqueTags])

  return (
    <div
      className={`w-full ${isSelected ? 'active' : ''} ${isBaselineTenant ? 'pointer-events-none shadow-none' : ''} alignment-search-item shadow-md break-all rounded-sm light-grey-bg px-3 py-2`}
      onClick={onClick}
      onKeyDown={event => {
        if (event.key === 'Enter') onClick()
      }}
      role='button'
      tabIndex={0}
    >
      <div className='grid-title'>
        <div className='flex flex-row justify-between'>
          <b className='text-lg'>{tenantFriendlyName}</b>

          {tenantBeingBackedUp && (
            <div data-testid='loading-spinner' className='spinner-medium' />
          )}

          {!isBaselineTenant && !tenantBeingBackedUp && (
            <AlignmentLight
              score={alignmentScore}
              alignedThreshold={alignedThreshold}
              semiAlignedThreshold={semiAlignedThreshold}
            />
          )}
        </div>

        <div className='flex flex-row items-center gap-2'>
          {visibleTags?.map(tag => (
            <Tag
              text={tag.tag}
              description={tag.description}
              colourRGB={hexToRgb(tag.colour)}
            />
          ))}
          <TagsPopover tags={hiddenTags} />
        </div>

        <div className='mt-2 mb-3'>
          {isSharedBaseline && <PolicyLibraryTag type='shared' />}
        </div>
      </div>

      <div
        className='grid-content'
        style={{
          gridColumn: isSharedBaseline ? 'col1-start / col2-end' : 'col1-start',
        }}
      >
        <div className='flex flex-col items-end leading-none'>
          {!isBaselineTenant && (
            <div className='text-sm max-lg:text-xs flex flex-row justify-between w-full pr-2'>
              <span className='opacity-75'>Alignment Score</span>
              <b>{alignmentScore ? `${alignmentScore.toFixed(2)}%` : 'None'}</b>
            </div>
          )}
          <div className='text-sm max-lg:text-xs flex flex-row justify-between w-full pr-2'>
            <span className='opacity-75'>Last Backup</span>
            <b>
              {lastBackupTimestamp
                ? formatToLocalTimeNoSeconds(lastBackupTimestamp)
                : 'No Backup Found'}
            </b>
          </div>
        </div>
      </div>

      <div className='grid-action-area'>
        {!isSharedBaseline &&
          (isRefreshing ? (
            <div className='w-full whitespace-nowrap'>
              {failed + succeeded} / {total}
            </div>
          ) : (
            <button
              type='button'
              disabled={tenantBeingBackedUp}
              className={`py-2 px-3 bg-[rgb(var(--cyan))] ${tenantBeingBackedUp ? 'opacity-50' : ''} pointer-events-auto text-white rounded-sm whitespace-nowrap mr-1 text-xs flex flex-row`}
              onClick={onBackupClick}
            >
              Back Up
              <FontAwesomeIcon icon={faServer} className='pl-1' />
            </button>
          ))}
      </div>
    </div>
  )
}

AlignmentTenant.defaultProps = {
  isSelected: false,
  isBaselineTenant: false,
  alignedThreshold: undefined,
  semiAlignedThreshold: undefined,
  onClick: () => {},
  tenantTags: [],
}

AlignmentTenant.propTypes = {
  tenantSummary: summaryTenantShape.isRequired,
  tenantTags: arrayOf(tenantTagsShape),
  onBackupClick: func.isRequired,
  onClick: func,
  alignedThreshold: number,
  semiAlignedThreshold: number,
  isSelected: bool,
  isBaselineTenant: bool,
}

export default AlignmentTenant
