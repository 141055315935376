import { useState, useEffect } from 'react'
import { shape, string, number, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '../../../tooltip/Tooltip'

import { useSelection } from '../context/SelectionContext'
import { useTenant } from '../../../../contexts/TenantContext'

import PolicyViewJSON from '../../../PolicyViewJSON/PolicyViewJSON'

/**
 * PolicySubGroupItem component
 * @param {object} item - item
 * @returns {JSX.Element}
 */
const PolicySubGroupItem = ({ item }) => {
  const {
    state: { selectedPolicies },
    selectItem,
  } = useSelection()

  const tenant = useTenant()

  const [isChecked, setIsChecked] = useState(
    selectedPolicies.some(policy => policy.id === item.id)
  )

  const { readOnly, displayName, description } = item

  useEffect(() => {
    setIsChecked(selectedPolicies.some(policy => policy.id === item.id))
  }, [selectedPolicies, item])

  const handleCheckboxToggle = () => {
    if (!readOnly) {
      const newCheckedState = !isChecked
      selectItem(item, newCheckedState)
    }
  }

  return (
    <button
      type='button'
      className={`flex justify-between items-center p-2 w-full text-left bg-neutral-50 mt-2 mb-2 ${readOnly ? 'cursor-not-allowed' : ''}`}
      onClick={handleCheckboxToggle}
      aria-disabled={readOnly}
    >
      <div className='flex items-center flex-1'>
        {readOnly ? (
          <span className='mr-2'>
            <Tooltip content='Read only policy'>
              <FontAwesomeIcon icon={faLock} />
            </Tooltip>
          </span>
        ) : (
          <input
            type='checkbox'
            className='mr-2 pointer-events-none'
            checked={isChecked}
            readOnly
          />
        )}
        <div className='px-4 py-3'>
          <h2 className='text-sm font-semibold'>{displayName}</h2>
          <p className='text-xs text-gray-700 text-base'>
            {description === null ? 'No Description' : description}
          </p>
        </div>
      </div>

      <PolicyViewJSON item={item} tenant={tenant[0]} />
    </button>
  )
}

export default PolicySubGroupItem

PolicySubGroupItem.propTypes = {
  item: shape({
    readOnly: bool,
    id: number.isRequired,
    displayName: string.isRequired,
    description: string,
  }).isRequired,
}
