import { useForm } from 'react-hook-form'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import {
  validateDomain,
  validateEmail,
  validateGuid,
  validateMinLength,
} from '../../utils/Validation'
import Alert from '../Alert/Alert'
import FormControlButtons from '../Form/components/FormControlButtons/FormControlButtons'
import VariableFormTextInput from '../Form/components/VariableFormTextInput/VariableFormTextInput'
import useUpdateClientTenantVariables from './api/useUpdateClientTenantVariables'

const EditClientTenantVariablesForm = ({
  clientVariables,
  clientTenantVariables,
  tenant,
  closePanel,
  onSuccessfulAction,
  isLoading,
}) => {
  const defaultValues = clientTenantVariables?.reduce((acc, tenantVariable) => {
    acc[`${tenantVariable.id}`] = tenantVariable.value || null
    return acc
  }, {})

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: 'onBlur',
    values: defaultValues,
  })

  const updateClientVariables = useUpdateClientTenantVariables(() => {
    reset()
    onSuccessfulAction()
    closePanel()
  }, tenant.clientTenantId)

  const onSubmit = data => {
    const variables = Object.entries(data).map(([id, value]) => ({
      id,
      value: value || null,
    }))

    const payload = {
      variables,
    }

    updateClientVariables.mutate(payload)
  }

  const resetForm = () => {
    reset()
    updateClientVariables.reset()
  }

  const getInputType = (type, hidden) => {
    if (hidden) {
      return 'password'
    }

    return type === 'email' ? 'email' : 'text'
  }

  const validateVariable = (value, variable) => {
    if (!value) {
      // variable values are optional and can be left unconfigured
      return true
    }
    const { type } = variable

    if (!validateMinLength(value, 5)) {
      return 'Value must be at least 5 characters'
    }

    if (type === 'email' && !validateEmail(value)) {
      return 'This variable must contain a valid email address'
    }

    if (type === 'domain' && !validateDomain(value)) {
      return 'This variable must contain a valid domain'
    }

    if (type === 'guid' && !validateGuid(value)) {
      return 'This variable must contain a valid GUID'
    }

    return true
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      aria-label='client-tenant-variables-form'
      autoComplete='off'
    >
      <div className='flex flex-col gap-4 content-between mt-4 pl-4 pr-4'>
        {clientVariables.map(variable => (
          <VariableFormTextInput
            key={variable.id}
            label={variable.name}
            subLabel={variable.description}
            register={() =>
              register(`${variable.id}`, {
                validate: value => validateVariable(value, variable),
              })
            }
            placeholder='Enter a value'
            builtIn={variable.builtIn}
            type={getInputType(variable.type, variable.hidden)}
            typeDisplay={variable.type}
            errors={errors[variable.id]}
            isLoading={isLoading}
          />
        ))}

        {updateClientVariables?.error && (
          <Alert
            type='error'
            title={updateClientVariables.error.response.data.message}
            margin='mt-3'
          >
            An error occurred while updating the variables.
            <ul>
              {updateClientVariables.error.response.data.errors?.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}

        <FormControlButtons
          onCancel={() => {
            reset()
            closePanel()
            onSuccessfulAction()
            resetForm()
          }}
          onReset={() => resetForm()}
          submitText='Save'
          resetDisabled={!isDirty || updateClientVariables.isPending}
          confirmDisabled={
            !isDirty ||
            Object.values(errors).length !== 0 ||
            updateClientVariables.isPending
          }
        />
      </div>
    </form>
  )
}
EditClientTenantVariablesForm.defaultProps = {
  clientVariables: [],
  clientTenantVariables: [],
  isLoading: false,
}

EditClientTenantVariablesForm.propTypes = {
  clientVariables: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      builtIn: bool.isRequired,
      type: string.isRequired,
      hidden: bool.isRequired,
      description: string,
    })
  ),
  clientTenantVariables: arrayOf(
    shape({
      id: string,
      value: string,
    })
  ),
  tenant: shape({
    clientTenantId: number.isRequired,
  }).isRequired,
  closePanel: func.isRequired,
  onSuccessfulAction: func.isRequired,
  isLoading: bool,
}

export default EditClientTenantVariablesForm
