import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import ClientVariablesActions from './ClientVariablesActions'
import Checkmark from '../Checkmark/Checkmark'

const tableColumnDefinitions = ({
  handleEditVariable,
  setIsContentPanelOpen,
}) => [
  {
    header: 'Variable Name',
    accessor: 'name',
    cell: ({ row }) => (
      <>
        <code
          className={`bg-[#f1f1f1] rounded-sm p-1 ${row.original.builtIn ? 'opacity-75' : ''}`}
        >
          {row.original.name}
        </code>
        {row.original.builtIn && (
          <FontAwesomeIcon icon={faLock} className='text-gray-500 ml-1' />
        )}
      </>
    ),
  },
  {
    header: 'Description',
    accessor: 'description',
  },
  {
    header: 'Hidden?',
    accessor: 'hidden',
    cell: ({ row }) => Checkmark(row.original.hidden),
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    header: 'Type',
    accessor: 'type',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row }) => (
      <ClientVariablesActions
        id={row.original.id}
        name={row.original.name}
        description={row.original.description}
        hidden={row.original.hidden}
        type={row.original.type}
        builtIn={row.original.builtIn}
        onEdit={handleEditVariable}
        onSuccess={setIsContentPanelOpen}
      />
    ),
  },
]

export default { tableColumnDefinitions }
