import { string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRotateRight,
  faClockRotateLeft,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { useFeatureFlag } from 'configcat-react'

import { driftTypes } from '../helpers'
import { formatToLocalTime } from '../../HomeDashboard/utils'
import HorizontalDivider from '../../HorizontalDivider'
import { driftedDiffShape } from '../../../utils/propTypes/tenantAlignmentProps'
import DriftedValue from './DriftedValue'
import Tooltip from '../../tooltip/Tooltip'

const DeviationDetails = ({ dataDiff, reason, username, timestamp }) => {
  const { value: enableDeviationDrift } = useFeatureFlag(
    'deviationDrift',
    false
  )

  const previouslyAcceptedDeviations = dataDiff.filter(
    diff => diff.driftType === driftTypes.driftDetected
  )

  const newDeviationsDetected = dataDiff.filter(
    diff => diff.driftType === driftTypes.newDeviationDetected
  )

  return (
    <>
      <div className='bg-inforcer-cyan/15 p-1 rounded mb-4'>
        {previouslyAcceptedDeviations.length > 0 && enableDeviationDrift && (
          <section
            aria-label='Previously Accepted Deviations'
            className='flex flex-col gap-1'
          >
            <span className='flex flex-row gap-1 items-center'>
              <FontAwesomeIcon icon={faClockRotateLeft} />
              <h3 className='font-semibold text-lg'>
                Previously Accepted Deviations
              </h3>
              <Tooltip
                content='These were the values when the deviation was accepted, and may have deviated further.
                It is important to reference the policy diff below to view the currently deviated values.'
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
            {previouslyAcceptedDeviations.map(({ path, driftedProperties }) => (
              <DriftedValue
                key={path}
                customerLabel='Accepted Customer Value:'
                customerValue={JSON.stringify(
                  driftedProperties[0].deviationSubjectValue
                )}
                path={path}
                baselineLabel='Accepted Baseline Value:'
                baselineValue={JSON.stringify(
                  driftedProperties[0].deviationBaselineValue
                )}
              />
            ))}
          </section>
        )}

        <div className='flex flex-col gap-1 py-4'>
          <span>
            <strong className='mr-1'>Reason:</strong>
            {reason}
          </span>
          <span>
            <strong className='mr-1'>Accepted By:</strong>
            {username}
          </span>
          <span>
            <strong className='mr-1'>Date Accepted:</strong>
            {formatToLocalTime(timestamp)}
          </span>
        </div>

        {newDeviationsDetected.length > 0 && enableDeviationDrift && (
          <section
            aria-label='New Deviations Detected'
            className='flex flex-col gap-1 pt-3'
          >
            <span className='flex flex-row gap-1 items-center'>
              <FontAwesomeIcon icon={faArrowRotateRight} />
              <h3 className='font-semibold text-lg'>New Deviations Detected</h3>
              <Tooltip content="These are newly deviated properties that weren't previously accepted">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
            {newDeviationsDetected.map(({ path, driftedProperties }) => (
              <DriftedValue
                key={path}
                customerLabel='Current Customer Value:'
                customerValue={JSON.stringify(
                  driftedProperties[0].currentSubjectValue
                )}
                path={path}
                baselineLabel='Current Baseline Value:'
                baselineValue={JSON.stringify(
                  driftedProperties[0].currentBaselineValue
                )}
              />
            ))}
          </section>
        )}
      </div>
      <div className='mb-3'>
        <HorizontalDivider />
      </div>
    </>
  )
}

DeviationDetails.defaultProps = {
  dataDiff: [],
}

DeviationDetails.propTypes = {
  username: string.isRequired,
  reason: string.isRequired,
  timestamp: string.isRequired,
  dataDiff: driftedDiffShape,
}

export default DeviationDetails
