import { shape, string, arrayOf } from 'prop-types'
import PolicyCategory from '../PolicyCategory/PolicyCategory' // Add missing import statement
import ExpandButton from '../components/ExpandButton'
import Accordion from '../components/Accordion'
import { useExpansion } from '../context/ExpansionContext'
import { useSelection } from '../context/SelectionContext'
import PolicySubGroupItem from '../PolicySubGroupItem/PolicySubGroupItem'

/**
 * PolicySubGroup component
 * @param {Object} parentGroup - parentGroup
 * @param {Object} subGroup - subGroup
 */
const PolicySubGroup = ({ parentGroup, subGroup }) => {
  const { isExpanded, toggleExpand, toggleExpandAll, isAllExpanded } =
    useExpansion()
  const {
    state: { selectedPolicies },
    selectGroup,
  } = useSelection()

  const { name, categories } = subGroup

  const policiesInSubGroup = categories.flatMap(category =>
    category.items.filter(item => !item.readOnly)
  )

  // Reduce function to extract all names
  const allGroups = [subGroup].reduce((acc, group) => {
    // Add the group name
    acc.push(group.name)
    // Iterate over categories within the subGroups and add their names
    group.categories.forEach(category => {
      acc.push(category.name)
    })

    return acc
  }, [])

  const handleExpandAll = () => {
    toggleExpandAll(allGroups, parentGroup)
  }

  const selectAll = policiesInSubGroup.every(item =>
    selectedPolicies.some(policy => item.id === policy.id)
  )

  const handleSelectAll = () => {
    selectGroup(policiesInSubGroup, !selectAll)
  }

  const handleToggleExpand = e => {
    e.stopPropagation()
    toggleExpand(name)
  }

  const isGroupExpanded = isExpanded(name)

  return (
    <div className={`mb-5 bg-white rounded ${isGroupExpanded ? 'border' : ''}`}>
      <Accordion
        toggleExpand={handleToggleExpand}
        handleSelectAll={handleSelectAll}
        selectAll={selectAll}
        tooltipText={`Select all in ${name}`}
        backgroundColor='bg-white'
      >
        <span className='font-semibold'>{name}</span>
        <div className='ml-auto flex items-center'>
          <button
            type='button'
            onClick={e => {
              e.stopPropagation()
              handleExpandAll()
            }}
            className='p-2 bg-gray-800 text-white text-sm rounded mr-2'
          >
            {isAllExpanded(allGroups) ? 'Collapse All' : 'Expand All'}
          </button>
          <ExpandButton isExpanded={isGroupExpanded} />
        </div>
      </Accordion>

      {isGroupExpanded && (
        <div className='m-2'>
          {categories.map(category =>
            category.name === null ? (
              category.items.map(item => (
                <PolicySubGroupItem key={item.name} item={item} />
              ))
            ) : (
              <PolicyCategory key={category.id} category={category} />
            )
          )}
        </div>
      )}
    </div>
  )
}

export default PolicySubGroup

PolicySubGroup.propTypes = {
  parentGroup: string.isRequired,
  subGroup: shape({
    name: string.isRequired,
    categories: arrayOf(
      shape({
        name: string.isRequired,
        items: arrayOf(
          shape({
            name: string.isRequired,
            type: string.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
}
