import { arrayOf, func, number, oneOf, shape, string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGear, faUsersGear } from '@fortawesome/free-solid-svg-icons'
import { useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import useGetTenantSummary from '../hooks/useGetTenantSummary'
import useRefreshTenant from '../hooks/useRefreshTenant'
import Modal from '../../Modal/Modal'
import {
  pendingChangesStates,
  usePendingChangesStateContext,
} from '../../../contexts/PendingChangesStateContext'
import {
  alignmentComparisonSortOptions,
  alignmentScoreFilterOptions,
} from '../../../config/TenantAlignmentConfig'
import {
  tenantRefreshStates,
  useTenantRefreshStateContext,
} from '../../../contexts/TenantRefreshStateContext'
import ModalActionButtons from '../../Modal/ModalActionButtons'
import Alert from '../../Alert/Alert'
import AlignmentTenant from '../AlignmentTenant'
import AlignmentTenantsListSkeleton from './AlignmentTenantsListSkeleton'
import { singleFilterShape } from '../../../utils/propTypes/tenantAlignmentProps'

const AlignmentTenantsList = ({
  tenantTags,
  selectedTenantTags,
  selectedBaseline,
  setBaselineTenant,
  setSelectedTenant,
  selectedTenant,
  invalidateTenantComparison,
  tenantScoreFilter,
  sortBy,
  searchValue,
}) => {
  const [tenantToRefresh, setTenantToRefresh] = useState(undefined)
  const [modalOpen, setModalOpen] = useState(false)
  const [numPendingChanges, setNumPendingChanges] = useState(0)

  const { setTenantRefreshState, removeTenantBeingRefreshed } =
    useTenantRefreshStateContext()
  const { pendingChangesState } = usePendingChangesStateContext()

  const refreshTenant = useRefreshTenant(
    () => {
      setTenantToRefresh(undefined)
      setModalOpen(false)
    },
    () => {
      removeTenantBeingRefreshed(tenantToRefresh.clientTenantId)
    }
  )

  const updateNumPendingChanges = tenants => {
    if (selectedTenant) {
      const newNumPendingChanges = tenants.find(
        tenant => tenant.clientTenantId === selectedTenant.clientTenantId
      )?.pendingChanges?.inProgress
      setNumPendingChanges(newNumPendingChanges)
    }
  }

  const getTenantSummary = useGetTenantSummary(
    selectedBaseline.clientTenantGroupId,
    setBaselineTenant,
    invalidateTenantComparison,
    updateNumPendingChanges
  )

  const tenants = useMemo(() => {
    if (!getTenantSummary?.data?.tenants) return []

    return getTenantSummary.data.tenants
      .filter(tenant =>
        tenant.tenantFriendlyName
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
      .filter(({ alignmentScore }) => {
        const fullyAlignedThreshold = selectedBaseline.alignedThreshold
        const { semiAlignedThreshold } = selectedBaseline

        switch (tenantScoreFilter.value) {
          case 'fully-aligned':
            return alignmentScore >= fullyAlignedThreshold
          case 'semi-aligned':
            return (
              alignmentScore >= semiAlignedThreshold &&
              alignmentScore < fullyAlignedThreshold
            )
          case 'not-aligned':
            return alignmentScore < semiAlignedThreshold
          case 'none':
          default:
            return true
        }
      })
      .sort((a, b) => {
        switch (sortBy.value) {
          case 'a-z':
            return a.tenantFriendlyName.localeCompare(b.tenantFriendlyName)
          case 'score':
          default:
            return a.alignmentScore - b.alignmentScore
        }
      })
  }, [
    getTenantSummary,
    searchValue,
    selectedBaseline,
    tenantScoreFilter,
    sortBy.value,
  ])

  if (getTenantSummary.isLoading) return <AlignmentTenantsListSkeleton />

  if (getTenantSummary.isError)
    return (
      <Alert type='error' title='Error fetching tenants'>
        <div className='flex flex-col gap-1'>
          {getTenantSummary.error.response.data.errors.map(errorMessage => (
            <div key={errorMessage}>{errorMessage}</div>
          ))}
        </div>
      </Alert>
    )

  const { baselineTenant } = getTenantSummary.data

  const displayPendingChangesAlert =
    numPendingChanges > 0 ||
    pendingChangesState === pendingChangesStates.actionInitiated ||
    pendingChangesState === pendingChangesStates.awaitingPendingChanges

  const filteredTenants =
    selectedTenantTags && selectedTenantTags.length > 0
      ? tenants.filter(tenant =>
          selectedTenantTags.some(tag => tenant.tags.includes(tag.value))
        )
      : tenants

  return (
    <div className='sticky top-0'>
      {displayPendingChangesAlert && (
        <div className='pb-2'>
          <Alert type='warning' title='Pending Changes'>
            <p>
              {`There are ${numPendingChanges || ''} pending policy actions that may not yet reflect their final state.
                  These will update automatically once all the operations have completed.`}
            </p>
          </Alert>
        </div>
      )}
      <div className='flex gap-3 items-center pb-2'>
        <h3 className='text-2xl'>Baseline Tenant</h3>
        <FontAwesomeIcon icon={faUserGear} size='xl' className='mb-2' />
      </div>
      {!baselineTenant ? (
        <div className='flex flex-col gap-1 p-4 border-2 text-red-500'>
          <div>No Baseline Tenant found</div>
        </div>
      ) : (
        <AlignmentTenant
          onBackupClick={() => {
            setTenantToRefresh(baselineTenant)
            setModalOpen(true)
          }}
          tenantSummary={baselineTenant}
          isBaselineTenant
        />
      )}
      <div className='flex flex-row gap-2 items-center mt-3'>
        <h3 className='text-2xl mt-2'>Aligned Tenants</h3>
        <FontAwesomeIcon icon={faUsersGear} size='xl' />
      </div>
      {!filteredTenants || filteredTenants.length === 0 ? (
        <div className='flex flex-col gap-1 p-4 border-2 text-red-500'>
          <div>No Aligned Tenants found</div>
        </div>
      ) : (
        <div
          className='flex flex-col gap-2 alignment-search-list'
          data-testid='tenant-list'
        >
          {filteredTenants.map(tenant => (
            <AlignmentTenant
              key={tenant.clientTenantId}
              tenantSummary={tenant}
              isSelected={
                selectedTenant?.clientTenantId === tenant.clientTenantId
              }
              alignedThreshold={selectedBaseline.alignedThreshold}
              semiAlignedThreshold={selectedBaseline.semiAlignedThreshold}
              onClick={() => {
                document
                  .getElementById('tenant-alignment-title')
                  .scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                  })
                updateNumPendingChanges(tenants)
                setSelectedTenant(tenant)
              }}
              onBackupClick={event => {
                event.stopPropagation()
                setTenantToRefresh(tenant)
                setModalOpen(true)
              }}
              tenantTags={tenantTags}
              selectedTenantTags={selectedTenantTags}
            />
          ))}
        </div>
      )}
      <Modal setModalOpen={setModalOpen} isOpen={modalOpen}>
        <h3 className='text-center mb-6'>Backup Tenant Policies</h3>
        <div className='text-center'>
          {refreshTenant.isPending ? (
            <Skeleton height={25} />
          ) : (
            <>
              You are about to backup all policies for{' '}
              <b>{tenantToRefresh?.tenantFriendlyName}</b>. Are you sure you
              want to continue?
            </>
          )}
        </div>
        {refreshTenant.isError && (
          <p className='text-red-500 text-center mt-[24px]'>
            {refreshTenant.error.message}
          </p>
        )}
        <ModalActionButtons
          onConfirm={() => {
            setTenantRefreshState(
              tenantToRefresh.clientTenantId,
              tenantRefreshStates.actionInitiated
            )
            refreshTenant.mutate(tenantToRefresh.clientTenantId)
          }}
          onCancel={() => {
            setModalOpen(false)
            setTenantToRefresh(undefined)
          }}
          confirmEnabled={!refreshTenant.isPending}
          cancelEnabled={!refreshTenant.isPending}
        />
      </Modal>
    </div>
  )
}

AlignmentTenantsList.defaultProps = {
  selectedBaseline: undefined,
  selectedTenant: null,
  searchValue: '',
}

AlignmentTenantsList.propTypes = {
  selectedBaseline: shape({
    clientTenantGroupId: string,
    alignedThreshold: number,
    semiAlignedThreshold: number,
  }),
  setBaselineTenant: func.isRequired,
  setSelectedTenant: func.isRequired,
  selectedTenant: shape({
    clientTenantId: number,
  }),
  invalidateTenantComparison: func.isRequired,
  tenantScoreFilter: oneOf(alignmentScoreFilterOptions).isRequired,
  sortBy: oneOf(alignmentComparisonSortOptions).isRequired,
  searchValue: string,
  tenantTags: arrayOf(singleFilterShape).isRequired,
  selectedTenantTags: arrayOf(singleFilterShape).isRequired,
}

export default AlignmentTenantsList
