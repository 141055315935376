import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { bool, func, node } from 'prop-types'
import { useFeatureFlag } from 'configcat-react'
import AlignmentLight from './AlignmentLight'
import Checkbox from '../Checkbox'
import { isDriftPolicy, shouldDeleteFromBaseline } from './helpers'
import { isTenantLevelSettingPolicyType } from '../../utils/Policy'
import ConditionalTooltipWrapper from '../ConditionalTooltipWrapper'
import { policyShape } from '../../utils/propTypes/tenantAlignmentProps'

const selectedTenantString = 'Selected Tenant'
const baselineTenantString = 'Baseline'

const AlignmentResultItem = ({
  policy,
  loading,
  description,
  footer,
  onAcceptDeviation,
  onRejectDeviation,
  onDeployPolicy,
  onDelete,
  onToggleSelect,
  selected,
  disabled,
}) => {
  const [collapsed, setCollapsed] = useState(true)

  const { value: enableDeviationDrift } = useFeatureFlag(
    'deviationDrift',
    false
  )

  const {
    policyGuid,
    policyName,
    policyTypeId,
    policyTypeName,
    isMissingFromBaseline,
    isMissingFromSubject,
    isDeviation,
    isDeviationAccepted,
    matchingSubjectPoliciesCount,
    isPolicyDeviationAcceptable,
    isPolicyDeployable,
    errorMessage,
    policyDataDiff,
  } = policy

  const score = useMemo(() => {
    if (!isDeviation || isDeviationAccepted) return 100
    if (isMissingFromSubject) return 95
    return 0
  }, [isDeviation, isDeviationAccepted, isMissingFromSubject])

  const isDriftedDeviation = isDriftPolicy(policyDataDiff)

  const isDeletable = useMemo(() => {
    if (isTenantLevelSettingPolicyType(policyTypeId)) return false

    // If it's a duplicate policy, it's not deleteable
    if (matchingSubjectPoliciesCount > 1) return false

    // if it only exists in the baseline (e.g. a suggested policy) -> not deleteable
    return !(isMissingFromSubject && !isMissingFromBaseline)
  }, [
    policyTypeId,
    matchingSubjectPoliciesCount,
    isMissingFromSubject,
    isMissingFromBaseline,
  ])

  const status = useMemo(() => {
    if (enableDeviationDrift && isDriftedDeviation)
      return 'Previously Accepted Deviation'
    if (
      !isDeviation &&
      !isMissingFromSubject &&
      !isMissingFromBaseline &&
      !isDeviationAccepted
    )
      return 'Aligned'
    if (
      isDeviation &&
      !isMissingFromSubject &&
      !isMissingFromBaseline &&
      isDeviationAccepted
    )
      return 'Accepted Deviation'
    if (
      isDeviation &&
      !isMissingFromSubject &&
      !isMissingFromBaseline &&
      !isDeviationAccepted
    )
      return 'Unaccepted Deviation'
    if (isMissingFromSubject && isDeviationAccepted) return 'Accepted Deviation'
    if (isMissingFromSubject && !isDeviationAccepted)
      return 'Recommended from Baseline'
    if (isMissingFromBaseline && isDeviationAccepted)
      return 'Accepted Deviation'
    if (isMissingFromBaseline && !isDeviationAccepted)
      return 'Existing Customer Policy'
    return 'Unknown Status'
  }, [
    enableDeviationDrift,
    isDriftedDeviation,
    isDeviation,
    isMissingFromSubject,
    isMissingFromBaseline,
    isDeviationAccepted,
  ])

  const deleteTargetName = shouldDeleteFromBaseline(
    isMissingFromSubject,
    isMissingFromBaseline
  )
    ? baselineTenantString
    : selectedTenantString

  return (
    <div
      aria-label={`policy container ${policyGuid}`}
      aria-expanded={!collapsed}
      className='alignment-result-item relative light-grey-bg rounded-md'
    >
      {loading && (
        <div className='absolute top-0 left-0 right-0 w-full'>
          <div className='h-1 w-full overflow-hidden'>
            <div className='progress w-full h-full bg-pink-500 left-right' />
          </div>
        </div>
      )}
      <div
        className='alignment-result-header flex justify-between items-center cursor-pointer p-4'
        onClick={() => setCollapsed(!collapsed)}
        onKeyDown={event => {
          if (event.key === 'Enter') setCollapsed(!collapsed)
        }}
        role='button'
        tabIndex={0}
      >
        <div
          role='none'
          className='rounded-[50%] mr-3 bg-white'
          onClick={e => e.stopPropagation()}
          onKeyDown={e => e.key === 'Enter' && e.stopPropagation()}
        >
          <ConditionalTooltipWrapper
            tooltipText={
              policy.isExchangeCompatible
                ? 'Pending changes in progress'
                : 'Re-onboard tenant to enable exchange'
            }
            condition={disabled}
          >
            <Checkbox
              id={`select-policy-${policyGuid}`}
              role='checkbox'
              checked={selected}
              onChange={onToggleSelect}
              disabled={disabled}
            />
          </ConditionalTooltipWrapper>
        </div>
        <div className='text-sm flex-grow alignment-result-name'>
          <b className='text-base'>{policyName}</b>
          <div className='opacity-90'>
            <b>Policy Type: </b>
            {policyTypeName}
          </div>
          {errorMessage ? <p>{errorMessage}</p> : ''}
        </div>
        <div className='flex items-center'>
          <div className='mr-3 text-xs text-right'>{status}</div>
          <AlignmentLight
            score={score}
            isDriftedDeviation={enableDeviationDrift && isDriftedDeviation}
          />
          <FontAwesomeIcon
            className='ml-4'
            icon={collapsed ? faChevronDown : faChevronUp}
          />
        </div>
      </div>
      <div
        className={`alignment-result-description p-4${collapsed ? ' hidden' : ''}`}
      >
        <div className='mb-4'>{description}</div>
        <div className='text-right flex flex-row justify-end'>
          {(isDeviation || isMissingFromSubject) && isDeviationAccepted && (
            <button
              type='button'
              className='btn cyan-btn text-xs ml-1'
              disabled={loading || disabled}
              onClick={onRejectDeviation}
            >
              Unaccept Deviation
            </button>
          )}
          {(isDeviation || isMissingFromSubject) &&
            (!isDeviationAccepted || isDriftedDeviation) &&
            isPolicyDeviationAcceptable && (
              <button
                type='button'
                className='btn cyan-btn text-xs ml-1'
                disabled={loading || disabled}
                onClick={onAcceptDeviation}
              >
                Accept Deviation
              </button>
            )}
          {isPolicyDeployable && (
            <ConditionalTooltipWrapper
              condition={disabled}
              tooltipText={
                policy.isExchangeCompatible
                  ? 'Pending changes in progress'
                  : 'Re-onboard tenant to enable exchange'
              }
            >
              <button
                type='button'
                className='btn cyan-btn text-xs ml-1'
                disabled={loading || disabled}
                onClick={onDeployPolicy}
              >
                Align to Baseline
              </button>
            </ConditionalTooltipWrapper>
          )}
          {isDeletable ? (
            <button
              type='button'
              className='btn cyan-btn text-xs ml-1'
              disabled={disabled || loading}
              onClick={onDelete}
            >
              {`Delete Policy from ${deleteTargetName}`}
            </button>
          ) : null}
        </div>
      </div>
      {footer ? (
        <div
          className={`alignment-result-description p-4${collapsed ? ' hidden' : ''}`}
        >
          {footer}
        </div>
      ) : null}
    </div>
  )
}

AlignmentResultItem.defaultProps = {
  footer: null,
}

AlignmentResultItem.propTypes = {
  policy: policyShape.isRequired,
  loading: bool.isRequired,
  description: node.isRequired,
  footer: node,
  onAcceptDeviation: func.isRequired,
  onRejectDeviation: func.isRequired,
  onDeployPolicy: func.isRequired,
  onDelete: func.isRequired,
  onToggleSelect: func.isRequired,
  selected: bool.isRequired,
  disabled: bool.isRequired,
}

export default AlignmentResultItem
