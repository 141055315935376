const uniqueTenantTags = ({ tenants, tenantTags }) => {
  const uniqueTags = new Set()
  if (!tenants || !tenantTags || tenantTags.length === 0) return []

  tenants?.forEach(tenant => {
    tenant?.tags.forEach(tag => uniqueTags.add(tag))
  })

  const tagsArray = Array.from(uniqueTags).map(tagId =>
    tenantTags?.find(tag => tag.id === tagId)
  )

  // Sort the array by the label property alphabetically (A-Z)
  return tagsArray.sort((a, b) => a.label?.localeCompare(b.label))
}

export default uniqueTenantTags
