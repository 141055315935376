import { useState } from 'react'
import { bool, func, object } from 'prop-types'

import Skeleton from 'react-loading-skeleton'

import PopupDialog from '../PopupDialog'

import useAcceptDeviation from '../../api/useAcceptDeviation'

import Alert from '../Alert/Alert'

const AcceptPolicyDeviationDialog = ({
  isOpen,
  onCloseDialog,
  deviationPayload,
  onSuccess,
}) => {
  const { policies = [], tenant = {}, baseline = {} } = deviationPayload || {}

  const [deviationReason, setDeviationReason] = useState('')

  const acceptDeviation = useAcceptDeviation(data => {
    if (!data?.failures.length > 0) {
      onSuccess()
      onCloseDialog()
    }
  })

  const { isError, isPending, error, data } = acceptDeviation

  const failures = data?.failures || error?.response?.data?.data?.failures || []
  const successes = data?.successes || []

  const hasFailures = failures.length > 0
  const hasSuccesses = successes.length > 0
  const totalCount = failures.length + successes.length

  const copyToClipboard =
    hasFailures &&
    `Baseline client ID: ${baseline?.clientTenantId}\nTenant Client: ${tenant?.clientTenantId}\nFailed: ${failures?.map(failure => failure.errors.map(failureError => `${failure?.policy?.policyName} - ${failureError}`).join('\n')).join('\n')}\nSuccessful: ${successes?.map(success => success?.policy?.policyName).join('\n')}`

  return (
    <PopupDialog
      open={isOpen}
      onClose={() => {
        setDeviationReason('')
        onCloseDialog()
      }}
    >
      {isPending ? (
        <>
          <h3 className='text-center mb-6'>Accepting deviations</h3>
          <Skeleton count={5} />
        </>
      ) : (
        <>
          <h3 className='text-center mb-3'>
            {hasFailures
              ? `Failed accepting ${failures.length} of ${totalCount} ${failures.length >= 1 ? 'deviations' : 'deviation'}`
              : 'Accept Deviations'}
          </h3>

          <div className='p-2'>
            {hasFailures &&
              failures.map(failure => (
                <div key={failure?.policy.policyName} className='mt-2'>
                  <Alert type='error'>
                    <p className='font-semibold'>
                      {failure?.policy.policyName}
                    </p>
                    <ul>
                      {failure.errors.map(failureError => (
                        <li key={failure.policy.policyName}>{failureError}</li>
                      ))}
                    </ul>
                  </Alert>
                </div>
              ))}

            {hasSuccesses &&
              successes.map(success => (
                <div key={success.policy.policyName} className='mt-2'>
                  <Alert type='success'>{success.policy.policyName}</Alert>
                </div>
              ))}
          </div>

          {!hasFailures && (
            <>
              <div className='text-center'>
                <ol className='ps-5 mt-2 pb-4 space-y-1 list-decimal list-inside text-left'>
                  {policies?.map(policy => (
                    <li key={policy.policyName}>{policy.policyName}</li>
                  ))}
                </ol>
                <p className='mt-2 text-base font-semibold'>
                  You are about to accept <b>{policies.length}</b>{' '}
                  {policies.length > 1 ? 'deviations' : 'deviation'} for
                  <b> {tenant.tenantFriendlyName}</b>.
                </p>
                <p className='text-base font-semibold'>
                  Are you sure you want to continue?
                </p>
              </div>
              <div>
                <div className='flex flex-col space-y-2 mt-6'>
                  <label
                    htmlFor='deviationReason'
                    className='font-semibold text-center'
                  >
                    {`Please enter a reason for ${policies.length > 1 ? 'these deviations' : 'this deviation'}`}
                  </label>
                  <input
                    id='deviationReason'
                    type='text'
                    value={deviationReason}
                    onChange={e => setDeviationReason(e.target.value)}
                    className='border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
                  />
                </div>
              </div>
              {isError && (
                <strong className='text-center pt-4 text-red-500'>
                  {error?.response?.data?.message}
                </strong>
              )}
            </>
          )}
        </>
      )}

      <div className='footer mt-8 flex justify-between mb-2'>
        {hasFailures ? (
          <button
            type='button'
            className='btn navy-btn mr-4'
            onClick={() => {
              onSuccess()
              onCloseDialog()
            }}
          >
            Close
          </button>
        ) : (
          <button
            type='button'
            className='btn navy-btn mr-4'
            disabled={isPending}
            onClick={() => {
              onCloseDialog()
            }}
          >
            Cancel
          </button>
        )}

        {!hasFailures ? (
          <button
            type='button'
            className='btn cyan-btn'
            disabled={
              deviationReason.trim().length === 0 || acceptDeviation.isPending
            }
            onClick={() => {
              acceptDeviation.mutate({
                acceptedReason: deviationReason,
                subjectClientTenantId: tenant.clientTenantId,
                baselineClientTenantId: baseline.clientTenantId,
                policies,
              })
            }}
          >
            Confirm
          </button>
        ) : (
          <button
            type='button'
            className='btn cyan-btn'
            onClick={() => {
              navigator.clipboard.writeText(copyToClipboard)
            }}
          >
            Copy to clipboard
          </button>
        )}
      </div>

      {isError && (
        <Alert type='error'>
          <h4>{error?.message}</h4>
          <p className='text-red-500 mt-[24px]'>
            {error?.response?.data?.message}
          </p>
        </Alert>
      )}
    </PopupDialog>
  )
}

AcceptPolicyDeviationDialog.defaultProps = {
  deviationPayload: undefined,
}

AcceptPolicyDeviationDialog.propTypes = {
  isOpen: bool.isRequired,
  onCloseDialog: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  deviationPayload: object,
  onSuccess: func.isRequired,
}

export default AcceptPolicyDeviationDialog
